<template>
  <v-slide-x-transition>
    <v-navigation-drawer
      absolute
      permanent
      right
      sit-above
      @click.native="() => setShowAdvisorProfileMenu(false)"
    >
      <template v-slot:prepend>
        <v-list-item two-line>
          <v-list-item-avatar>
            <v-icon>mdi-account</v-icon>
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title>{{ currentUserName }}</v-list-item-title>
            <v-list-item-subtitle>Advisor</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </template>
      <v-divider />

      <v-list dense>
        <v-list-item class="wl-pointer">
          <v-btn
            text
            solo
            width="100%"
            @click.native="() => $router.push('/advisor/trade-review')"
            text-xs-left
          >
            <v-icon>mdi-book-check-outline</v-icon>
            Trade Review
          </v-btn>
        </v-list-item>
        <v-list-item class="wl-pointer">
          <v-btn
            text
            solo
            width="100%"
            @click.native="() => setShowResultsPanel(true)"
            text-xs-left
          >
            <v-icon>mdi-magnify</v-icon>
            Search
          </v-btn>
        </v-list-item>
      </v-list>

      <v-divider />
      <v-list dense>
        <v-list-item class="wl-pointer">
          <v-btn text solo width="100%" @click.native="$router.push('/logout')">
            <v-icon>mdi-logout</v-icon>
            Logout
          </v-btn>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </v-slide-x-transition>
</template>

<script>
import { authComputed } from '@/state/helper'
import { mapActions } from 'vuex'
export default {
  computed: {
    ...authComputed,
  },
  methods: {
    ...mapActions({
      setShowAdvisorProfileMenu: 'profileMenu/setShowAdvisorProfileMenu',
      setShowResultsPanel: 'search/setShowResultsPanel',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
.sit-above {
  z-index: 99;
}
.v-navigation-drawer {
  height: 100vh;
  position: fixed;
  top: 0;
  width: 60vw;
  right: 0;
}
</style>
