<template>
  <div>
    <v-app-bar dense flat>
      <v-spacer></v-spacer>
      <GlobalSearchBar :show-view-results-icon="true" />
      <v-subheader
        class="wl-pointer"
        @click="() => setShowAdvisorProfileMenu(true)"
      >
        <v-avatar>
          <v-icon>mdi-account</v-icon>
        </v-avatar>
        {{ currentUserName }}
      </v-subheader>
    </v-app-bar>
    <AdvisorProfileMenu v-if="showAdvisorProfileMenu" />
    <GlobalSearchResults v-show="showResultsPanel" />
    <router-view />
  </div>
</template>

<script>
import { authComputed } from '@/state/helper'
import GlobalSearchBar from '@/components/GlobalSearchBar'
import GlobalSearchResults from '@/components/GlobalSearchResults'
import AdvisorProfileMenu from '@/components/AdvisorProfileMenu'
import { mapGetters, mapActions } from 'vuex'
export default {
  components: {
    GlobalSearchBar,
    GlobalSearchResults,
    AdvisorProfileMenu,
  },
  data() {
    return {
      searchExpanded: false,
    }
  },
  computed: {
    ...authComputed,
    ...mapGetters({
      showResultsPanel: 'search/getShowResultsPanel',
      showAdvisorProfileMenu: 'profileMenu/getShowAdvisorProfileMenu',
    }),
  },
  methods: {
    ...mapActions({
      setShowAdvisorProfileMenu: 'profileMenu/setShowAdvisorProfileMenu',
    }),
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
