<template>
  <v-expand-transition>
    <v-flex xs4>
      <v-text-field
        color="secondary"
        class="wl-global-search-input"
        prepend-inner-icon="mdi-magnify"
        label="Search"
        clearable
        v-model="queryString"
        @keydown="keydown"
        @click:clear="$emit('cleared')"
        :append-outer-icon="
          showViewResultsIcon && (queryResults || []).length
            ? 'mdi-text-search'
            : undefined
        "
        @click:append-outer="() => setShowResultsPanel(true)"
      />
    </v-flex>
  </v-expand-transition>
</template>

<script>
import { mapActions, mapMutations, mapGetters } from 'vuex'
export default {
  props: {
    initialQueryString: { type: String, default: undefined },
    showViewResultsIcon: { type: Boolean, default: false },
  },
  data() {
    return {
      queryString: undefined,
    }
  },
  computed: {
    ...mapGetters({
      queryResults: 'search/getQueryResults',
    }),
  },
  watch: {
    initialQueryString(value) {
      this.queryString = value
    },
  },
  methods: {
    ...mapActions({ executeQuery: 'search/executeQuery' }),
    ...mapMutations({
      setShowResultsPanel: 'search/setShowResultsPanel',
      setQueryString: 'search/setQueryString',
    }),

    async execute(queryString) {
      this.setShowResultsPanel(true)
      this.setQueryString(queryString)
      await this.executeQuery(queryString)
    },
    keydown($event) {
      if (
        $event.key === 'Enter' &&
        this.queryString &&
        this.queryString.length
      ) {
        this.execute(this.queryString)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/styles/global.scss';
</style>
